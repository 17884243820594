<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Experiencias</h4>
                  <h1>Nuevo Pedido</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <div class="flex">
                    <ion-button @click="exit" shape="round" style="margin-top: 6px" color="dark" fill="outline">
                        <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
                        <ion-label>Volver</ion-label>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col size="12" size-lg="6">
                </ion-col>
              </ion-row>
          </ion-grid>
        </section>

        <section id="lists" class="mb-lg fullwidth-mobile">
          <ion-grid>
              <ion-row>
                  <ion-col size="6" size-md="4" size-lg="3" size-xl="3" class="product" v-for="experiencia in elements.filter(x => x.active === true)" :key="experiencia.objectID">
                    <ion-card button @click="openModal(experiencia)">
                        <ion-card-content>
                            <img :src="experiencia.images[0][512]" alt="Thumbnail"/>
                            <b>{{ experiencia.name }}</b>
                        </ion-card-content>
                    </ion-card>
                  </ion-col>
              </ion-row>
          </ion-grid>
        </section>
      </div>

      <Footer></Footer>
  </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonCard, IonCardContent, modalController } from '@ionic/vue';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

import Header from '@/components/Header.vue';
import NewExperiencia from './components/NewExperiencia.vue';

export default defineComponent({
  name: 'ExperienciasTPV',
  components: {
    IonCard,
    IonCardContent,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    Header,
  },
    computed: {
      ...mapState('experiencias121', {
        elements: state => state.experiencias,
      }),

      ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('experiencias121',['getExperiencias']),

        async openModal(experiencia) {
            const modal = await modalController
                .create({
                    component: NewExperiencia,
                    cssClass: 'modal-details',
                    componentProps: {
                        experiencia,
                    },
                })
            return modal.present();
        },

        exit(){
            this.$router.go(-1);
        },
    },
    setup() {
    return { arrowBackOutline };
    },
    created(){
        this.getExperiencias();
    }
});
</script>

<style scoped>
  .product{
    padding: 0.75rem;
  }

  .product ion-card{
    display: flex;
    align-items: flex-start;
    min-height: 100%;
    text-align: center;
  }

  .product ion-card img{
      width: 100%;
      height: 200px;
      border-radius: 1rem;
      object-fit: cover;
      object-position: center;
      margin-bottom: 1rem;
  }

</style>

<template> 
    <ion-content class="bindingScrollContent" :scroll-events="true" :fullscreen="true">
    <div class="item-details">
        <ion-header class="ion-no-border bb header-details" >
          <ion-toolbar color="transparent">
            <ion-buttons slot="start" style="margin: 0">
              <ion-button @click="exit">
                <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <h5 style="padding-left: 0.5rem">Nuevo Pedido</h5>
          </ion-toolbar>
        </ion-header>


          <section id="experiencia" v-if="experiencia">
              <swiper id="slider-steps" :autoHeight="true" @swiper="setStepSlider" :slidesPerView="1" :spaceBetween="16">
                 <swiper-slide class="slide-step">
                    <h5 class="section-title">Personalización</h5>

                    <ion-list lines="none" class="element-list">
                      <ion-item-group v-for="(element, i) in experiencia.elements" :key="element">
                        <ion-list-header class="subtitulo">
                          <ion-label text-wrap>{{ element.name }}</ion-label>
                        </ion-list-header>

                        <ion-radio-group v-model="order.items[0].items[i]">
                          <ion-item v-for="(option, e) in element.options" :key="e" class="element-option">
                            <ion-label text-wrap v-if="!option.img">{{ option.name }}</ion-label>
                            <img v-if="option.img" class="option-image" :src="option.img['512']" alt="Experiencia" />
                            <ion-radio slot="end" :value="option"></ion-radio>
                          </ion-item>
                        </ion-radio-group>

                        <hr class="separator">
                      </ion-item-group>

                      <ion-item-group v-if="experiencia.extras && experiencia.extras.length">
                        <ion-list-header class="subtitulo">
                          <ion-label text-wrap>Extras</ion-label>
                        </ion-list-header>

                          <ion-item v-for="(extra, e) in experiencia.extras" :key="e" class="element-option">
                            <ion-label text-wrap v-if="!extra.img">
                              {{ extra.name }}
                              <h6>Precio: {{ extra.price }}€</h6>
                            </ion-label>
                            <img v-if="extra.img" class="option-image" :src="extra.img['512']" alt="Experiencia" />
                            <ion-checkbox slot="end" @update:modelValue="addExtra($event, extra)" :modelValue="order.items.findIndex(x => x.name === extra.name) !== -1"></ion-checkbox>
                          </ion-item>

                        <hr class="separator">
                      </ion-item-group>

                      <ion-item lines="none">
                        <ion-label>Apto para Celiacos</ion-label>
                        <ion-checkbox slot="end" @update:modelValue="addCeliacos($event)" :modelValue="order.items.findIndex(x => x.objectID === 'celiacos') !== -1"></ion-checkbox>
                      </ion-item>
                
                    </ion-list>

                    <ion-button style="margin-top: 2rem" @click="nextStep" expand="full" shape="round" color="primary">
                      <ion-label>Siguiente</ion-label>
                    </ion-button>
                    <ion-button style="margin-top: 1rem; margin-bottom: 1rem;" @click="backStep" expand="full" fill="outline" shape="round" color="dark">
                      <ion-label>Atrás</ion-label>
                    </ion-button>
                  </swiper-slide>

                  <swiper-slide class="slide-step">
                    <h5 class="section-title">Entrega</h5>

                    <form>
                        <ion-grid>
                            <ion-row>
                              <ion-col size="12" class="box-input" :class="{ error: v$.order.deliveryDate.$errors.length }" v-if="experiencia.objectID !== 'FG1GvP9j4s4yYS1dS24v'">
                                <Calendar id="deliveryDate" class="input" v-if="experiencia.objectID !== 'ehmhPkWuwi385ZcMUUS3'" v-model.lazy="v$.order.deliveryDate.$model" placeholder="Selccionar Día*" :minDate="minDate()" :disabledDays="[0]"  :manualInput="false" />
                                
                                <Calendar id="deliveryDate" class="input" v-if="experiencia.objectID === 'ehmhPkWuwi385ZcMUUS3'" v-model.lazy="v$.order.deliveryDate.$model" placeholder="Selccionar Día*" :minDate="minDateValentin()" :maxDate="maxDateValentin()"   :manualInput="false" />
                                <!-- error message -->
                                <div class="input-errors" v-for="(error, index) of v$.order.deliveryDate.$errors" :key="index">
                                  <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                              </ion-col>
                              <!--<ion-col size="12" class="box-input" :class="{ error: v$.order.deliveryDate.$errors.length }" v-if="experiencia.objectID === 'FG1GvP9j4s4yYS1dS24v'">
                                <Calendar id="deliveryDate" class="input" v-model.lazy="v$.order.deliveryDate.$model" placeholder="Selccionar Día*" :minDate="minDateNavidad()" :maxDate="maxDateNavidad()" :disabledDates="invalidDates" :manualInput="false" />

                                <div class="input-errors" v-for="(error, index) of v$.order.deliveryDate.$errors" :key="index">
                                  <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                              </ion-col>-->
                              <ion-col size="12" class="box-input" :class="{ error: v$.order.deliveryHour.$errors.length }">
                                <Dropdown v-model.lazy="v$.order.deliveryHour.$model" class="input" :options="deliveryHours"  placeholder="Seleccionar Hora*" />
                                <!-- error message -->
                                <div class="input-errors" v-for="(error, index) of v$.order.deliveryHour.$errors" :key="index">
                                  <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                              </ion-col>
                              <ion-col size="12" class="box-input" :class="{ error: v$.order.deliveryName.$errors.length }">
                                <InputText class="input" id="deliveryName" placeholder="Nombre del Destinaratio" type="text" v-model.trim.lazy="v$.order.deliveryName.$model" />
                                <!-- error message -->
                                <div class="input-errors" v-for="(error, index) of v$.order.deliveryName.$errors" :key="index">
                                  <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                              </ion-col>
                            </ion-row>

                            <h6 class="text-center">Los campos marcados con (*) son obligatorios.</h6>
                        </ion-grid>
                        <ion-button style="margin-top: 2rem" @click="nextStep" expand="full" shape="round" color="primary" :disabled="v$.order.$invalid">
                          <ion-label>Siguiente</ion-label>
                        </ion-button>
                        <ion-button style="margin-top: 1rem; margin-bottom: 1rem;" @click="backStep" expand="full" fill="outline" shape="round" color="dark">
                          <ion-label>Atrás</ion-label>
                        </ion-button>
                      </form>
                  </swiper-slide>

                  <swiper-slide class="slide-step">
                    <h5 class="section-title">Registro</h5>
                    <form>
                      <ion-grid>
                          <ion-row>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.firstName.$errors.length }">
                              <InputText class="input" id="firstName" placeholder="Nombre*" type="text" v-model.trim.lazy="v$.customer.firstName.$model" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.firstName.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.lastName.$errors.length }">
                              <InputText class="input" id="lastName" placeholder="Apellidos*" type="text" v-model.trim.lazy="v$.customer.lastName.$model" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.lastName.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.birthDate.$errors.length }">
                              <Calendar id="birthDate" class="input" placeholder="Fecha de Nacimiento" v-model.lazy="v$.customer.birthDate.$model" :maxDate="maxDate()" dateFormat="dd-mm-yy" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.birthDate.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.sex.$errors.length }">
                              <Dropdown v-model.trim.lazy="v$.customer.sex.$model" class="input" :options="sexs" optionLabel="name" optionValue="code" placeholder="Sexo" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.sex.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.phone.$errors.length }">
                              <InputText class="input" id="phone" placeholder="Teléfono*" type="tel" minLength="9" maxLength="9" v-model.trim.lazy="v$.customer.phone.$model" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.phone.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.email.$errors.length }">
                              <InputText class="input" id="email" placeholder="Email*" type="email" v-model.trim.lazy="v$.customer.email.$model" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.email.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>

                            <b class="weight-bold text-center" style="padding: 1rem 0; width: 100%">Dirección de Entrega</b>

                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.address.$errors.length }">
                                <InputText class="input" id="address" placeholder="Dirección Completa*" type="text" v-model.trim.lazy="v$.customer.address.$model" />
                                <!-- error message -->
                                <div class="input-errors" v-for="(error, index) of v$.customer.address.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.city.$errors.length }">
                                <InputText class="input" id="city" placeholder="Ciudad*" type="text" v-model.trim.lazy="v$.customer.city.$model" />
                                <!-- error message -->
                                <div class="input-errors" v-for="(error, index) of v$.customer.city.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.zipCode.$errors.length }">
                                <InputText class="input" id="zipCode" placeholder="Código Postal*" type="number" minLength="5" maxLength="5" v-model.trim.lazy="v$.customer.zipCode.$model" />
                                <!-- error message -->
                                <div class="input-errors" v-for="(error, index) of v$.customer.zipCode.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                            </ion-col>
                          </ion-row>

                          <h6 class="text-center">Los campos marcados con (*) son obligatorios.</h6>
                      </ion-grid>

                      <ion-button style="margin-top: 2rem" @click="nextStep" expand="full" shape="round" color="primary" :disabled="v$.customer.$invalid">
                        <ion-label>Siguiente</ion-label>
                      </ion-button>
                      <ion-button style="margin-top: 1rem; margin-bottom: 1rem;" @click="backStep" expand="full" fill="outline" shape="round" color="dark">
                        <ion-label>Atrás</ion-label>
                      </ion-button>
                    </form>

                  </swiper-slide>

                  <swiper-slide class="slide-step">
                    <h5 class="section-title" style="padding-bottom: 2rem">Finalizar</h5>

                    <ion-list lines="none" class="platos">
                        <div v-for="(dish) in order.items" :key="dish.id" >
                            <ion-item class="dish">
                                <ion-label class="name weight-bold">{{ dish.name }}</ion-label>
                                
                                <ion-buttons slot="end" class="quantitys">                                   
                                    <ion-label>{{ dish.quantity }}</ion-label>
                                </ion-buttons>

                                <p class="text-primary price" slot="end" v-if="dish.price">{{ (dish.price * dish.quantity).toFixed(2) }} €</p>
                                <p class="text-primary price" slot="end" v-if="!dish.price">GRATIS</p>
                            </ion-item>

                            <ion-list class="subplatos" v-if="dish.items" lines="none">
                                <ion-item v-for="item in dish.items" :key="item.id" class="subitem">
                                    <ion-label class="name">{{ item.name }}</ion-label>
                                
                                    <ion-buttons slot="end" class="quantitys">                                       
                                        <ion-label>{{ dish.quantity }}</ion-label>
                                    </ion-buttons>

                                    <p class="text-primary price" slot="end">INCL.</p>
                                </ion-item>
                            </ion-list>
                        </div>                     
                    </ion-list>

                    <ion-list class="totals" lines="none">
                        <ion-item>
                            <ion-label class="weight-bold">Subtotal</ion-label>
                            <ion-text class="price weight-bold" slot="end">{{ order.subtotal.toFixed(2) }}€</ion-text>
                        </ion-item>
                        <ion-item>
                            <ion-label class="weight-bold">Envío</ion-label>
                            <ion-text class="price weight-bold" slot="end">{{ order.shipping.toFixed(2) }}€</ion-text>
                        </ion-item>
                        <ion-item v-if="order.discount">
                            <ion-label class="weight-bold">Descuento Cupón ({{ order.coupon.code }})</ion-label>
                            <ion-text class="price weight-bold" slot="end">-{{ order.discount.toFixed(2) }}€</ion-text>
                        </ion-item>
                        <ion-item class="total">
                            <ion-label class="weight-bold">Total</ion-label>
                            <ion-text class="price weight-bold" color="dark" slot="end">{{ order.total.toFixed(2) }}€</ion-text>
                        </ion-item>
                        <ion-item>
                            <p class="text-right" style="width: 100%">Incluye ({{ order.tax.toFixed(2) }}€) de I.V.A.</p>
                        </ion-item>
                    </ion-list>

                    <div style="margin-top: 2rem;" v-if="experiencia.objectID === 'FG1GvP9j4s4yYS1dS24v'">
                        <span class="p-input-icon-right input">
                            <i class="pi pi-circle-off" v-if="couponOK === null" />
                            <i class="pi pi-check-circle text-success" v-if="couponOK === true" />
                            <i class="pi pi-times-circle text-danger" v-if="couponOK === false" />
                            <InputText type="text" class="input text-uppercase" v-on:keyup="checkCoupons" v-model.trim.lazy="activeCoupon" placeholder="Cupón Promocional" />
                        </span>
                    </div>

                     <div style="margin-top: 2rem">
                      <Textarea v-model="order.observations" placeholder="Observaciones" :autoResize="true" rows="3"/>
                    </div>

                    <div size="12">
                      <Dropdown v-model.lazy="order.payment.method" class="input" :options="paymentMethods" optionLabel="name" optionValue="code" placeholder="Método de Pago*" />
                    </div>

                    <ion-button style="margin-top: 2rem" @click="payment" expand="full" shape="round" color="primary" :disabled="order.total <= 0">
                      <ion-label>Crear Pedido</ion-label>
                    </ion-button>
                    <ion-button style="margin-top: 1rem; margin-bottom: 1rem;" @click="backStep" expand="full" fill="outline" shape="round" color="dark">
                      <ion-label>Atrás</ion-label>
                    </ion-button>
                  </swiper-slide>
              </swiper>
          </section>
    </div>
    </ion-content>
</template>

<script>
import { IonContent, IonicSwiper, IonLabel, IonButton, IonIcon, IonText, IonButtons, IonHeader, IonToolbar, IonListHeader, IonList, IonRadio, IonItem, IonRadioGroup, IonItemGroup, IonGrid, IonRow, IonCol, IonCheckbox, loadingController, modalController } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

import SwiperCore, { Navigation, Pagination, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

SwiperCore.use([IonicSwiper, Navigation, Pagination, Controller]);

import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import moment from 'moment';

import { mapActions, mapState } from 'vuex';
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@/utils/i18n-validators'
import { getFirestoreClient } from '@/firebaseConfig';


export default defineComponent({
  name: 'NewOrderExperiencia',
  props: ['experiencia'],
  components: {
    IonHeader,
    IonToolbar,
    IonContent,
    Swiper,
    SwiperSlide,
    Calendar,
    InputText,
    Dropdown,
    IonLabel,
    IonButton,
    IonListHeader,
    IonList,
    IonRadio,
    IonItem,
    IonRadioGroup,
    IonItemGroup,
    IonGrid,
    IonRow,
    IonCol,
    IonCheckbox,
    IonText,
    IonButtons,
    IonIcon,
    Textarea
  },
  data() {
    return {
        ProductSlider: { value: null},
        stepSlider: { value: null},

        deliveryHours: [],

        invalidDates: [
        ],

        sexs: [
          {name: 'Masculino', code: 'm'},
          {name: 'Femenino', code: 'f'},
          {name: 'Otro', code: 'o'},
        ],

        paymentMethods: [
          {name: 'Efectivo', code: 'cash'},
          {name: 'Tarjeta', code: 'dataphone'},
        ],
            
        order: {
          items: [
            {
              items: []
            }
          ],
          deliveryDate: null,
          deliveryHour: null,
          deliveryName: null,
          total: 0,
          subtotal: 0,
          tax: 0,
          shipping: 0,
          discount: 0,
          payment: {
            method: ''
          },
        },
        customer: {
          firstName: '',
          lastName: '',
          birthDate: null,
          sex: null,
          phone: '',
          email: '',
          privacy: false,
          terms: false,
          commercial: false,
        },

        couponOK: null,
        activeCoupon: '',
    }
  },
  validations() {
    return {
      order: {
        deliveryDate: { required },
        deliveryHour: { required },
        deliveryName: {  },
      },

      customer: {
        firstName: { required },
        lastName: { required },
        birthDate: { },
        sex: {  },
        phone: { required, minLength: minLength(9), maxLength: maxLength(9) },
        email: { required, email },
        privacy: { required },
        terms: { required },
        commercial: { },
        address: { required },
        city: { required },
        zipCode: { required, minLength: minLength(5), maxLength: maxLength(5) },
      }
    }
  },
  computed:{
      ...mapState(['evolbeUser'])
  },
  methods: {
    ...mapActions('experiencias121',['addOrder']),

    setStepSlider(swiper){
        this.stepSlider.value = swiper;
        this.stepSlider.value.allowSlideNext = false;
        this.stepSlider.value.allowSlidePrev = false;
    },

    nextStep(){
        this.stepSlider.value.allowSlideNext = true;
        this.stepSlider.value.slideNext(250, true);
        this.stepSlider.value.allowSlideNext = false;
        this.scrollToTop();

        this.recalculate()
    },

    backStep(){
        this.stepSlider.value.allowSlidePrev = true;
        this.stepSlider.value.slidePrev(250, true);
        this.stepSlider.value.allowSlidePrev = false;
    },

    scrollToTop(){
      const scrollContent = document.querySelector('ion-content.bindingScrollContent');
      scrollContent.scrollToTop(500)
    },

    goToSlide(index){
        this.ProductSlider.value.slideTo(index, 1000, true)
    },

    setProductSlider(swiper){
        this.ProductSlider.value = swiper;
    },

    addExtra(event, extra){
      if(event){
        const newItem = {
          quantity: 1,
          ...extra
        }
        this.order.items.push(newItem);
      } else {
        const index = this.order.items.findIndex(x => x.name === extra.name );
        this.order.items.splice(index,1);
      }
    },

    addCeliacos(event){
      if(event){
        const newItem = {
          quantity: 1,
          objectID: 'celiacos',
          price: 0,
          name: 'Apto para Celiacos'
        }
        this.order.items.push(newItem);
      } else {
        const index = this.order.items.findIndex(x => x.objectID === 'celiacos' );
        this.order.items.splice(index,1);
      }

      this.recalculate();
    },

    // Fechas
    minDate(){
      const mDate = moment().add(1, 'days');
      return new Date(mDate.toDate().getTime());
    },

    minDateValentin(){
      const ahora = new Date().getTime();
      let mDate = ahora;
      if(ahora > 1651190407000){
        mDate = moment(ahora).add(1, 'days');
      } else {
        mDate = moment(1651190407000);
      }
      return new Date(mDate.toDate().getTime());
    },

    maxDateValentin(){
      const mDate = moment(1651363200000);
      return new Date(mDate.toDate().getTime());
    },

    maxDate(){
      const mDate = moment().subtract(18, 'years');
      return new Date(mDate.toDate().getTime());
    },

    async getDeliveryHours(){
      const dbClient = getFirestoreClient(this.evolbeUser);
      const resp = await dbClient.collection('/settings').doc('opening-hours').get();
      const openingHours = resp.data();
      this.deliveryHours = openingHours.experiencias;
    },

    // Totales
    async recalculate(){
        let total = 0;
        let subtotal = 0;
        let tax = 0;
        this.order.discount = 0;
        this.order.shipping = 2.5;
        this.order.delivery = true;

        this.order.items.forEach(element => {
            subtotal = subtotal + (element.price * element.quantity);
        });

        // Cupones
        if(this.order.coupon){
            // El cupon es de descuento por cantiddad
            if(this.order.coupon.type === 'discount€'){
                this.order.discount = this.order.coupon.discount;
            }
            // El cupon es de descuento por porcentaje
            if(this.order.coupon.type === 'discount%'){
                this.order.discount = (subtotal * this.order.coupon.discount) / 100;
            }
        }

        // Total
        total = (subtotal + this.order.shipping) - this.order.discount;
        tax = subtotal * 0.10;

        this.order.subtotal = subtotal;
        this.order.total = total;
        this.order.tax = tax;
    },

    async checkCoupons(){
        if(this.activeCoupon){
            this.activeCoupon = this.activeCoupon.toUpperCase();
            const exist = this.coupons.find(x => x.code === this.activeCoupon);
            if(exist){
                // El cupon está en fecha
                const now = new Date();
                if(exist.dateStart <= now && exist.dateEnd >= now){
                    // El cupon tiene usos disponibles
                    if(exist.uses > 0){
                        // El pedido es superior al minimo
                        if(this.order.subtotal >= exist.minimumOrder){
                            this.couponOK = true;
                            this.order.coupon = exist;
                            this.recalculate();
                        } else {
                            this.$toast.add({severity: 'error', summary: 'Error al aplicar el cupón', detail: 'El pedido debe ser al menos de ' + exist.minimumOrder + '€', group: 'tr', life: 3000});
                            this.couponOK = false;
                            this.order.coupon = null;
                            this.recalculate();
                        }
                    } else {
                        this.$toast.add({severity: 'error', summary: 'Error al aplicar el cupón', detail: 'Se ha excedido el número de usos de este cupón', group: 'tr', life: 3000});
                        this.couponOK = false;
                        this.order.coupon = null;
                        this.recalculate();
                    }
                } else {
                    this.$toast.add({severity: 'error', summary: 'Error al aplicar el cupón', detail: 'El cupón ya no está disponible', group: 'tr', life: 3000});
                    this.couponOK = false;
                    this.order.coupon = null;
                    this.recalculate();
                }
            } else{
                this.couponOK = false;
                this.order.coupon = null;
                this.recalculate();
            }
        } else{
             this.couponOK = null;
             this.order.coupon = null;
             this.recalculate();
        }
    },

    async payment(){
      const loading = await loadingController
        .create({
          message: 'Creando...',
          duration: 10000,
        });

      await loading.present();

      this.order.dateCreated = new Date().getTime();
      this.order.status = 1;
      this.order.payment.status = 'pending';
      this.order.customer = this.customer;
      this.delivery = true;

      await this.addOrder({data: this.order});

      loadingController.dismiss();
      this.$toast.add({severity: 'success', summary: 'Pedido Creado', detail: 'El pedido se ha creado correctamente.', group: 'tr', life: 3000});
      modalController.dismiss();
    },

    exit(){
        modalController.dismiss();
    }
  },
  mounted() {
      if(this.experiencia.active){
        this.stepSlider.value.allowSlidePrev = true;
        this.stepSlider.value.slideTo(0, 0, true);
        this.stepSlider.value.allowSlidePrev = false;

        this.order.items[0] = {
          name: this.experiencia.name,
          price: this.experiencia.price,
          quantity: 1,
          images: this.experiencia.images,
          id: this.experiencia.id,
          description: this.experiencia.description,
          dateCreated: this.experiencia.dateCreated,
          dateUpdated: this.experiencia.dateUpdated,
          active: this.experiencia.active,
          items: [],
        }

        this.experiencia.elements.forEach((element, i) => {
          this.order.items[0].items[i] = element.options[0];
          this.order.items[0].items[i].quantity = 1;
        });

        this.recalculate();
      } else {
        modalController.dismiss();
      }

      this.getDeliveryHours();
  },
  setup() {
    return { v$: useVuelidate(), chevronBackOutline };
  }
});
</script>

<style scoped>
  .header-aside-container{
      max-width: 96rem;
      margin: 0 auto;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }

  .header-details{
    height: 5rem;
    padding: 1rem 0;
  }

  #experiencia{
    max-width: 720px;
    margin: 0 auto;
  }

  #relative-experiencias{
    max-width: 720px;
    margin: 3rem auto;
  }

  .slider-steps{
      justify-content: inherit;
      text-align: inherit;
      align-items: inherit;
      display: block;
      position: relative;
      top: 0;
      min-height: 70vh;
  }

  .slide-step{
      justify-content: inherit;
      text-align: inherit;
      align-items: inherit;
      display: block;
      position: relative;
      top: 0;
      min-height: 70vh;
  }

  .element-list .subtitulo ion-label{
      font-weight: bold;
      font-size: 1.25rem;
      text-transform: uppercase;
      color: var(--ion-color-dark) !important;
  }

  .main-swiper {
    width: 100%;
    height: 347px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 1rem;
  }

  .main-swiper a{
    width: 100%;
    height: auto;
  }

  .main-swiper img{
    width: 100%;
    height: 347px;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
  }

  .horizontal-swiper {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 1rem;
  }

  .horizontal-swiper a{
    width: 100%;
    height: auto;
  }

  .horizontal-swiper img{
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
  }

  .option-image{
    margin-top: 1rem;
  }

  .section-title{
      margin-bottom: 1rem !important;
      padding: 1.5rem 0;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid var(--ion-color-medium);
  }

  /* Totales */
  .dish, .totals > ion-item, .subitem{
        --padding-start: 0px;
        --inner-padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --padding-top: 0px;
        --inner-padding-top: 0px;
        --padding-bottom: 0px;
        --inner-padding-bottom: 0px;
        height: 36px;
    }

    .dish > ion-lablel, .totals > ion-label, .subitem > ion-label{
        margin: 0;
    }
    
    .price{
        font-weight: normal;
        margin: 0;
        width: 64px;
        text-align: right;
    }

    .quantitys{
        width: 96px;
        margin-left: 0;
    }

    .quantitys ion-label{
        margin: 0 auto;
    }

    .quantitys > ion-label{
        margin: 0 auto;
    }

    .subplatos{
        padding: 0;
    }

    .four-gallery {
      padding: 0.5rem;
    }
    .four-gallery ion-col{
      padding: 0.5rem;
    }


</style>